.header {
  background: #ffffff;
  padding: 0;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background-color: "#ffffff";
}
